<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="lista"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
        :pinnedBottomRowData="pinnedBottomRowData"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                lista.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : lista.length
              }}
              de {{ lista.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>
    
  <script>
import ListadoReportesMixin from "../../mixins/ListadoReportesMixin";
export default {
  mixins: [ListadoReportesMixin],
  name: "ListadoUtilidad",
  data() {
    return {
      columnDefs: [
        {
          headerName: "FECHA",
          field: "creado_en",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "USUARIO",
          field: "usuario_nombre",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "DESCRIPCION",
          field: "productos_descripcion",
          filter: false,
          minWidth: 300,
        },
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "COSTO",
          field: "costo",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "PRECIO",
          field: "precio",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },

        {
          headerName: "DESCUENTO",
          field: "descuento",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "SUBTOTAL",
          field: "subtotal",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "SUB. COSTO",
          field: "subtotalCosto",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "UTILIDAD",
          field: "utilidad",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
          cellClassRules: {
            red: function (params) {
              return params.value <= 0;
            },
          },
        },
      ],
    };
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          descuento: "TOTAL:",
          subtotal: this.subtotal,
          subtotalCosto: this.subtotalCosto,
          utilidad: this.utilidad,
        },
      ];
    },
    lista() {
      return this.items && this.items[0] ? this.items[0] : [];
    },
    subtotal() {
      return this.items && this.items[1] ? this.items[1].subtotal : 0;
    },
    subtotalCosto() {
      return this.items && this.items[2] ? this.items[2].subtotalCosto : 0;
    },
    utilidad() {
      return this.items && this.items[3] ? this.items[3].utilidad : 0;
    },
  },
  methods: {},
};
</script>
  <style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.red {
  color: #ea5455 !important;
}
</style>
    