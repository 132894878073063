import axiosIns from "@/libs/axios";

const getReporteUtilidad = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-utilidad?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteUtilidadPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-utilidad/pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

export default {
    getReporteUtilidad,
    getReporteUtilidadPdf,
}