<template>
  <div>
    <b-card :title="titulo">
      <b-card-text>
        <b-form>
          <validation-observer ref="form">
            <b-row>
              <b-col cols="12" md="4" xl="3">
                <b-form-group
                  label="Rango de Fechas:"
                  label-for="rangoFechas"
                  label-cols-lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Rango de Fechas"
                    rules="required"
                    vid="rangoFechas"
                  >
                    <flat-pickr
                      id="rangoFechas"
                      v-model="rangoFechas"
                      class="form-control form-control-sm-small"
                      :config="flatPickrConfig"
                      @on-change="onChange"
                      @on-close="onClose"
                      placeholder="Seleecione un rango de fechas"
                    />
                    <small class="text-danger text-small">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="8"
                xl="9"
                class="mt-1 mt-md-0 pt-personalizado"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  @click="restablecer"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="RotateCwIcon" class="mr-50" />
                    <span class="align-middle">RESTABLECER</span>
                  </span>
                  <feather-icon
                    icon="RotateCwIcon"
                    class="d-md-none"
                    size="14"
                  />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  class="ml-1"
                  @click="validar(false)"
                >
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  <span class="align-middle">CONSULTAR EN PANTALLA</span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="ml-1"
                  @click="validar(true)"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span class="align-middle">VISUALIZAR EN PDF</span>
                  </span>
                  <feather-icon icon="FileIcon" class="d-md-none" size="14" />
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card-text>

      <b-card-text>
        <component
          :is="componenteListado"
          ref="listado"
          :items="items"
          :loading="loading"
        ></component>
      </b-card-text>
    </b-card>

    <ModalMostrarPdf
      :titulo="`${titulo} del ${this.form.desde} al ${this.form.hasta}`"
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :mostrarModal="mostarModal"
      @cerrarModal="cerrarModal"
    />
  </div>
</template>
    
<script>
import Ripple from "vue-ripple-directive";
import ReportesMixin from "../../mixins/ReportesMixin";
import ListadoUtilidad from "../views/ListadoUtilidad.vue";
import { formatearFecha } from "../../utils";

export default {
  name: "SedeHastaDesdeLayout",
  mixins: [ReportesMixin],
  props: {
    servicioGetReporte: {
      type: Function,
      required: true,
    },
    componenteListado: {
      type: String,
      required: true,
      validator: (value) => {
        return ["ListadoUtilidad"].includes(value);
      },
    },
  },
  components: {
    ListadoUtilidad,
  },
  directives: {
    ripple: Ripple,
  },
  data() {
    return {
      form: {
        desde: formatearFecha(new Date()),
        hasta: formatearFecha(new Date()),
      },
    };
  },
  methods: {
    restablecer() {
      this.rangoFechas = [
        formatearFecha(new Date()),
        formatearFecha(new Date()),
      ];
      this.items = [];
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reportes.scss";
</style>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.text-small {
  font-size: 11px;
}

@media (min-width: 1500px) {
  .text-small {
    font-size: 12px;
  }
}
</style>
    